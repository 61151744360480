import React, { useEffect, useState } from "react";
import { 
    Box, Button, Grid, ListItemIcon, ListItemText, 
    Menu, MenuItem, Modal, Stack, Typography, useTheme 
} from '@mui/material'
import { 
    ArrowBack, Block, Build, Close, Edit, ForwardToInbox, KeyboardArrowDown, MarkEmailRead, PostAdd, 
} from '@mui/icons-material';
import { listAllHotelAgents, listBookingDetails, resetAllHotelAgents, resetBookingDetails } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StatusChip } from "../../components/Chips";
import { tokens } from "../../theme";
import UpdateBookingDatesModalContent from "./update-booking-dates-modal";
import SwapRoomModalContent from "./swap-room-modal";
import CancelBookingModal from "./cancel-booking";
import UpdateBookingStatus from "./update-booking-status";
import BookingUpdation from "./bookingUpdation";
import RoomDetails from "./roomDetails";
import ResendEmailsModal from "./resend-emails";
import moment from "moment";
// import { convertUnixToIstDate } from "../../utils";

function BookingDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [updation, setUpdation] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [resendEmailModal, setResendEmailModal] = useState(false);
    const [resendUpdatedEmailModal, setResendUpdatedEmailModal] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { bookingDetails, agentsList } = useSelector(
        ({
            booking: { bookingDetails },
            agents: {
                agents: { list },
            },
        }) => ({
            bookingDetails,
            agentsList: list,
        })
    );
    // console.log(bookingDetails);
    useEffect(() => {
        document.title = "Booking details";
        window.scrollTo(0, 0);
        dispatch(resetBookingDetails());
        dispatch(listBookingDetails({ bookingId: id }));
        dispatch(resetAllHotelAgents());
        dispatch(listAllHotelAgents({isForDropdown: true}));
    }, [dispatch, id, updation]);

    const modalStyle = {
        height: "100%",
        width: "100%",
        overflow: "auto",
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                {!updation && (
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                )}
                <Typography fontWeight="bold" variant="h4">
                    {updation && "UPDATE"} BOOKING DETAILS
                </Typography>
                {!updation ? (
                    <Button
                        size="small"
                        variant="outlined"
                        endIcon={<KeyboardArrowDown />}
                        onClick={handleMenuClick}
                        // disabled={bookingDetails?.type === "phs"}
                    >
                        <b>Update</b>
                    </Button>
                ) : (
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={() => setUpdation(false)}
                        >
                            <Close fontSize='small' />
                        </Button>
                        <Button
                            size='small'
                            type='submit'
                            form="bookingUpdation"
                            variant='contained'
                        // onClick={handleFormSubmit}
                        >
                            <b>Save</b>
                        </Button>
                    </Stack>
                )}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem
                        disabled={["checkin", "cancelled"].includes(bookingDetails?.status)}
                        onClick={() => navigate(`/update-checkin/${id}`)}
                    >
                        <ListItemIcon>
                            <PostAdd fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Add Check-in</ListItemText>
                    </MenuItem>

                    {/* {bookingDetails?.type !== "group" && (
                    <MenuItem
                        disabled={["checkin", "cancelled"].includes(bookingDetails?.status)}
                        onClick={() => setUpdateModal(true)}
                    >
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Update Booking Dates</ListItemText>
                    </MenuItem>
                    )} */}
                    
                    <MenuItem
                        disabled={bookingDetails?.status === "cancelled"}
                        onClick={() => {
                            handleMenuClose()
                            setUpdation(true)
                        }}
                    >
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Update Details</ListItemText>
                    </MenuItem>

                    <MenuItem
                        disabled={bookingDetails?.status === "checkin" || moment(moment.unix(bookingDetails?.checkout_date)).isBefore(moment().startOf("day"))}
                        onClick={() => {
                            handleMenuClose()
                            setUpdateStatusModal(true)
                        }}
                    >
                        <ListItemIcon>
                            <Build fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Update Status</ListItemText>
                    </MenuItem>
                    <MenuItem
                        disabled={bookingDetails?.status === "cancelled"}
                        onClick={() => {
                            handleMenuClose()
                            setResendEmailModal(true)
                        }}
                    >
                        <ListItemIcon>
                            <MarkEmailRead fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Resend Confirmation Email</ListItemText>
                    </MenuItem>
                    <MenuItem
                        disabled={bookingDetails?.status === "cancelled" || bookingDetails?.createdAt === bookingDetails?.updatedAt}
                        onClick={() => {
                            handleMenuClose()
                            setResendUpdatedEmailModal(true)
                            setResendEmailModal(true)
                        }}
                    >
                        <ListItemIcon>
                            <ForwardToInbox fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send Update Email</ListItemText>
                    </MenuItem>

                    {/* {bookingDetails?.type !== "group" && (
                    <MenuItem
                        disabled={["checkin", "cancelled"].includes(bookingDetails?.status)}
                        onClick={handleOpen}
                    >
                        <ListItemIcon>
                            <SwapHoriz fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Swap Room</ListItemText>
                    </MenuItem>
                    )} */}

                    {/* <MenuItem
                        disabled={bookingDetails?.status === "cancelled"}
                        onClick={() => {
                            handleMenuClose()
                            setCancelModal(true)
                        }}
                    >
                        <ListItemIcon>
                            <Cancel fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Cancel Booking</ListItemText>
                    </MenuItem> */}
                </Menu>
            </Stack>
            <Box width="100%" p={2} borderRadius={2} backgroundColor={colors.primary[400]}>
                {!updation ? (
                <Grid container rowSpacing={2} columnSpacing={{ md: 4, xs: 2 }}>
                    <Grid item md={12} xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            {/* {bookingDetails?.status === "hold" ? ( */}
                                <Button
                                    size="small"
                                    variant="outlined"
                                    // endIcon={<Block />}
                                    disabled={bookingDetails?.status === "cancelled"}
                                    onClick={() => {
                                        // localStorage.setItem("booking_id", bookingDetails?._id)
                                        navigate(`/unblock/${bookingDetails?.booking_id}`)
                                    }}
                                >
                                    <b>Add / Remove Rooms</b>
                                </Button>
                            {/* ) : null} */}
                            <Button
                                size="small"
                                variant="outlined"
                                endIcon={<Block />}
                                color="error"
                                disabled={bookingDetails?.status === "cancelled"}
                                onClick={() => {
                                    handleMenuClose()
                                    setCancelModal(true)
                                }}
                            >
                                <b>Cancel</b>
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Booking id:</Typography>
                                <Typography color={colors.greenAccent[500]}>
                                    <small>{bookingDetails?.booking_id || "-"}</small>
                                </Typography>
                            </Stack>
                            {bookingDetails?.reference_id ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Reference id:</Typography>
                                    <Typography>
                                        <small>{bookingDetails?.reference_id ?? "-"}</small>
                                    </Typography>
                                </Stack>
                            ) : null}
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Check-in date:</Typography>
                                <Typography>
                                    {bookingDetails?.checkin_date
                                        ? moment
                                              .unix(bookingDetails?.checkin_date)
                                              .format("DD-MMM-YYYY")
                                        : "-"}
                                    {/* {bookingDetails?.checkin_date
                                        ? convertUnixToIstDate(bookingDetails?.checkin_date)
                                        : "-"} */}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Check-out date:</Typography>
                                <Typography>
                                    {bookingDetails?.checkout_date
                                        ? moment
                                              .unix(bookingDetails?.checkout_date)
                                              .format("DD-MMM-YYYY")
                                        : "-"}
                                    {/* {bookingDetails?.checkout_date
                                        ? convertUnixToIstDate(bookingDetails?.checkout_date)
                                        : "-"} */}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Booking from:</Typography>
                                <Typography>{bookingDetails?.booking_from?.replace("_", " ") || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Booked on:</Typography>
                                <Typography>{bookingDetails?.booked_date || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Booking status:</Typography>
                                <StatusChip status={bookingDetails?.status} />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Total rooms:</Typography>
                                <Typography>{bookingDetails?.total_rooms || "0"}</Typography>
                            </Stack>
                            {bookingDetails?.extra_beds ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Extra beds:</Typography>
                                    <Typography>{bookingDetails?.extra_beds ?? "-"}</Typography>
                                </Stack>
                            ) : null}
                            {bookingDetails?.custom_price ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Special price:</Typography>
                                    <Typography>₹{bookingDetails?.custom_price || "0"}</Typography>
                                </Stack>
                            ) : (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Total price:</Typography>
                                    <Typography>₹{bookingDetails?.total_price || "0"}</Typography>
                                </Stack>
                            )}
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Paid amount:</Typography>
                                <Typography>₹{bookingDetails?.paid_amount || "0"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Unpaid amount:</Typography>
                                <Typography>₹{bookingDetails?.unpaid_amount || "0"}</Typography>
                            </Stack>
                            {bookingDetails?.cut_off_days ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Cut off period:</Typography>
                                    <Typography>{bookingDetails?.cut_off_days || "0"} Day(s)</Typography>
                                </Stack>
                            ) : null}
                            {bookingDetails?.meal_plan_id && (
                                <>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Meal plan:</Typography>
                                        <Typography>{bookingDetails?.meal_plan_id?.name || "-"}</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Total meals amount:</Typography>
                                        <Typography>{bookingDetails?.meal_plan_id?.rate || 0}</Typography>
                                    </Stack>
                                </>
                            )}
                            {bookingDetails?.remarks && (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Remarks:</Typography>
                                    <Typography>{bookingDetails?.remarks || "-"}</Typography>
                                </Stack>
                            )}
                            {bookingDetails?.preference && (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Preference:</Typography>
                                    <Typography>{bookingDetails?.preference || "-"}</Typography>
                                </Stack>
                            )}
                            {bookingDetails?.billing_instruction && (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"40%"}>Billing Instruction:</Typography>
                                    <Typography>{bookingDetails?.billing_instruction || "-"}</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Name:</Typography>
                                <Typography>{bookingDetails?.title || ""} {bookingDetails?.name || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Email:</Typography>
                                <Typography>{bookingDetails?.email || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Mobile:</Typography>
                                <Typography>{bookingDetails?.guest_mobile || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Phone:</Typography>
                                <Typography>{bookingDetails?.guest_phone || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Address:</Typography>
                                <Typography>{bookingDetails?.guest_address || "-"}</Typography>
                            </Stack>
                            {/* <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Payment method:</Typography>
                                <Typography>{bookingDetails?.payment_method || "-"}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"40%"}>Payment status:</Typography>
                                <Typography>{bookingDetails?.payment_status || "-"}</Typography>
                            </Stack> */}
                            {bookingDetails?.agent_id?.first_name && (
                                <>
                                    <Stack>
                                        <Typography variant="h4" color={colors.greenAccent[600]} fontWeight={700}>
                                            Agent Info
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Name:</Typography>
                                        <Typography>
                                            {bookingDetails?.agent_id?.first_name
                                                ? `${bookingDetails?.agent_id?.first_name} ${
                                                      bookingDetails?.agent_id?.last_name || ""
                                                  }`
                                                : "-"}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Email:</Typography>
                                        <Typography>
                                            {bookingDetails?.agent_id?.email || "-"}
                                        </Typography>
                                    </Stack>
                                    {bookingDetails?.agent_id?.mobile && (
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography width={"40%"}>Mobile:</Typography>
                                            <Typography>
                                                {bookingDetails?.agent_id?.mobile || "-"}
                                            </Typography>
                                        </Stack>
                                    )}
                                </>
                            )}
                            {bookingDetails?.sub_agent_id?.email && (
                                <>
                                    <Stack>
                                        <Typography variant="h4" color={colors.greenAccent[600]} fontWeight={700}>
                                            Sub Agent Info
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Name:</Typography>
                                        <Typography>
                                            {bookingDetails?.sub_agent_id?.name || "-"}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"40%"}>Email:</Typography>
                                        <Typography>
                                            {bookingDetails?.sub_agent_id?.email || "-"}
                                        </Typography>
                                    </Stack>
                                    {bookingDetails?.sub_agent_id?.mobile && (
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography width={"40%"}>Mobile:</Typography>
                                            <Typography>
                                                {bookingDetails?.sub_agent_id?.mobile || "-"}
                                            </Typography>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Stack>
                    </Grid>
                    <RoomDetails 
                        bookingId={id}
                        rooms={bookingDetails?.rooms} 
                        isCustomPrice={bookingDetails?.custom_price ? true : false}
                        colors={colors} 
                        modalStyle={modalStyle}
                    />
                </Grid>
                ) : (
                    <BookingUpdation 
                        bookingDetails={bookingDetails} 
                        setUpdation={setUpdation}
                        colors={colors}
                        dispatch={dispatch} 
                        agentsList={agentsList}
                    />
                )}
            </Box>

            <Modal open={open} onClose={handleClose} sx={modalStyle}>
                <>
                    <SwapRoomModalContent
                        bookingDetails={bookingDetails}
                        handleClose={handleClose}
                    />
                </>
            </Modal>
            <Modal open={updateModal} onClose={() => setUpdateModal(false)} sx={modalStyle}>
                <>
                    <UpdateBookingDatesModalContent
                        bookingDetails={bookingDetails}
                        setUpdateModal={setUpdateModal}
                    />
                </>
            </Modal>
            <Modal open={updateStatusModal} onClose={() => setUpdateStatusModal(false)} sx={modalStyle}>
                <>
                    <UpdateBookingStatus
                        bookingDetails={bookingDetails}
                        setUpdateStatusModal={setUpdateStatusModal}
                    />
                </>
            </Modal>
            <Modal
                open={resendEmailModal}
                onClose={() => {
                    setResendUpdatedEmailModal(false)
                    setResendEmailModal(false)
                }}
                sx={modalStyle}
            >
                <>
                    <ResendEmailsModal
                        bookingDetails={bookingDetails}
                        setResendEmailModal={setResendEmailModal}
                        isForUpdate={resendUpdatedEmailModal}
                        setResendUpdatedEmailModal={setResendUpdatedEmailModal}
                    />
                </>
            </Modal>
            <Modal open={cancelModal} onClose={() => setCancelModal(false)} sx={modalStyle}>
                <>
                    <CancelBookingModal
                        bookingDetails={bookingDetails}
                        setCancelModal={setCancelModal}
                    />
                </>
            </Modal>
        </Box>
    );
}

export default BookingDetails;
