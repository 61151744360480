import React, { useEffect } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { listHotelNotifications, resetHotelNotifications } from "../../store/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const HotelNotifications = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const { notifications } = useSelector(
    ({
      admin: { notifications }
    }) => ({
      notifications,
    })
  );

  useEffect(() => {
    document.title = 'Notifications';
    dispatch(resetHotelNotifications());
    dispatch(listHotelNotifications());
    // eslint-disable-next-line
  }, [dispatch]);

  const columns = [
    {
      field: "booking_id",
      headerName: "Booking id",
      cellClassName: "id-column--cell",
      width: 120,
      renderCell: ({ row }) => (
        <Typography
          noWrap
          fontSize={"small"}
          onClick={() => navigator.clipboard.writeText(row.booking_id || "")}
        >
          {row?.booking_id ? row.booking_id : "-"}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Guest info",
      width: 210,
      hideInXs: true,
      renderCell: ({ row: { name, email, guest_mobile } }) => {
        return (
          <Stack sx={{ wordBreak: "break-word" }}>
            <Typography color={colors.greenAccent[400]}>
              <b>{name}</b>
            </Typography>
            <Typography fontSize={"small"}>
              {email || ""} {guest_mobile ? `- ${guest_mobile}` : ""}
            </Typography>
          </Stack>
        )
      },
    },
    {
      field: "checkin_date",
      headerName: "Check-in date",
      width: 120,
      renderCell: ({ row }) => `${row?.checkin_date ? moment.unix(row.checkin_date).format("YYYY-MM-DD") : "-"}`,
    },
    { field: "paid_amount", headerName: "Paid amount", width: 100 },
    { field: "unpaid_amount", headerName: "Unpaid amount", width: 100 },
    {
      field: "booking_from",
      headerName: "Booking from",
      width: 150,
      renderCell: ({ row: { booking_from } }) => `${booking_from?.replace("_", " ")}`,
    },
    {
      field: "_id",
      headerName: "View details",
      width: 110,
      hideInXs: true,
      renderCell: ({ row: { booking_id } }) => {
        return (
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(`/booking-details/${booking_id}`)}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{ fontWeight: 600 }}
          >
            View
          </Button>
        )
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        m="20px 0"
      >
        <Header title="NOTIFICATIONS" subtitle="welcome to Notifications" />
      </Stack>
      <Box
        m="8px 0 0 0"
        height="68vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={notifications}
          columns={columns}
          getRowId={(row) => row?.booking_id}
        />
      </Box>
    </Box>
  );
};

export default HotelNotifications;
