export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SAVE_ALL_HIGHLIGHTS = "SAVE_ALL_HIGHLIGHTS";
export const RESET_ALL_HIGHLIGHTS_DATA = "RESET_ALL_HIGHLIGHTS_DATA";

export const SAVE_ALL_FACILITIES = "SAVE_ALL_FACILITIES";
export const RESET_ALL_FACILITIES_DATA = "RESET_ALL_FACILITIES_DATA";

export const SAVE_ALL_BOOKINGS = "SAVE_ALL_BOOKINGS";
export const RESET_ALL_BOOKINGS = "RESET_ALL_BOOKINGS";

export const SAVE_ALL_ROOMS_LIST = "SAVE_ALL_ROOMS_LIST";
export const RESET_ALL_ROOMS_LIST = "RESET_ALL_ROOMS_LIST";

export const SAVE_ROOM_DETAILS = "SAVE_ROOM_DETAILS";
export const RESET_ROOM_DETAILS = "RESET_ROOM_DETAILS";

export const SAVE_ALL_ROOM_TYPES_LIST = "SAVE_ALL_ROOM_TYPES_LIST";
export const RESET_ALL_ROOM_TYPES_LIST = "RESET_ALL_ROOM_TYPES_LIST";

export const SAVE_AVALABLE_ROOMS = "SAVE_AVALABLE_ROOMS";
export const RESET_AVALABLE_ROOMS = "RESET_AVALABLE_ROOMS";

export const SAVE_ALL_RATES = "SAVE_ALL_RATES";
export const RESET_ALL_RATES = "RESET_ALL_RATES";

export const SAVE_ALL_CALENDAR_REPORTS = "SAVE_ALL_CALENDAR_REPORTS";
export const RESET_ALL_CALENDAR_REPORTS = "RESET_ALL_CALENDAR_REPORTS";

export const SAVE_BOOKING_DETAILS = "SAVE_BOOKING_DETAILS";
export const RESET_BOOKING_DETAILS = "RESET_BOOKING_DETAILS";

export const SAVE_ROOMTYPE_DETAILS = "SAVE_ROOMTYPE_DETAILS";
export const RESET_ROOMTYPE_DETAILS = "RESET_ROOMTYPE_DETAILS";

export const SAVE_HOTEL_PROFILE = "SAVE_HOTEL_PROFILE";
export const RESET_HOTEL_PROFILE = "RESET_HOTEL_PROFILE";

export const SAVE_ALL_ROOMS_REPORTS = "SAVE_ALL_ROOMS_REPORTS";
export const RESET_ALL_ROOMS_REPORTS = "RESET_ALL_ROOMS_REPORTS";

export const SAVE_ALL_AVAILABILITY_REPORTS = "SAVE_ALL_AVAILABILITY_REPORTS";
export const RESET_ALL_AVAILABILITY_REPORTS = "RESET_ALL_AVAILABILITY_REPORTS";

export const SAVE_ALL_INVENTORY_REPORTS = "SAVE_ALL_INVENTORY_REPORTS";
export const RESET_ALL_INVENTORY_REPORTS = "RESET_ALL_INVENTORY_REPORTS";

export const SAVE_ALL_INVENTORY_SCHEDULES = "SAVE_ALL_INVENTORY_SCHEDULES";
export const RESET_ALL_INVENTORY_SCHEDULES = "RESET_ALL_INVENTORY_SCHEDULES";

export const SAVE_INVENTORY_SCHEDULE_DETAILS = "SAVE_INVENTORY_SCHEDULE_DETAILS";
export const RESET_INVENTORY_SCHEDULE_DETAILS = "RESET_INVENTORY_SCHEDULE_DETAILS";

export const SAVE_ALL_HOTEL_AGENTS = "SAVE_ALL_HOTEL_AGENTS";
export const RESET_ALL_HOTEL_AGENTS = "RESET_ALL_HOTEL_AGENTS";

export const SAVE_AGENT_DETAILS = "SAVE_AGENT_DETAILS";
export const RESET_AGENT_DETAILS = "RESET_AGENT_DETAILS";

export const SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN = "SAVE_ALL_SUB_AGENTS_FOR_DROPDOWN";
export const RESET_ALL_SUB_AGENTS_FOR_DROPDOWN = "RESET_ALL_SUB_AGENTS_FOR_DROPDOWN";

export const SAVE_ALL_MEAL_PLANS = "SAVE_ALL_MEAL_PLANS";
export const RESET_ALL_MEAL_PLANS = "RESET_ALL_MEAL_PLANS";

export const SAVE_ALL_ROOMTYPE_OFFERS = "SAVE_ALL_ROOMTYPE_OFFERS";
export const RESET_ALL_ROOMTYPE_OFFERS = "RESET_ALL_ROOMTYPE_OFFERS";

export const SAVE_ALL_ROOMTYPE_OTA_COUNT = "SAVE_ALL_ROOMTYPE_OTA_COUNT";
export const RESET_ALL_ROOMTYPE_OTA_COUNT = "RESET_ALL_ROOMTYPE_OTA_COUNT";

export const SAVE_ALL_HOTEL_USERS = "SAVE_ALL_HOTEL_USERS";
export const RESET_ALL_HOTEL_USERS = "RESET_ALL_HOTEL_USERS";

export const SAVE_ALL_HOTEL_OTAS = "SAVE_ALL_HOTEL_OTAS";
export const RESET_ALL_HOTEL_OTAS = "RESET_ALL_HOTEL_OTAS";

export const SAVE_HOTEL_DETAILS = "SAVE_HOTEL_DETAILS";
export const RESET_HOTEL_DETAILS = "RESET_HOTEL_DETAILS";

export const SAVE_AVAILABILITY_DATA = "SAVE_AVAILABILITY_DATA";
export const RESET_AVAILABILITY_DATA = "RESET_AVAILABILITY_DATA";

export const SAVE_ROOMTYPE_PRICING = "SAVE_ROOMTYPE_PRICING";
export const RESET_ROOMTYPE_PRICING = "RESET_ROOMTYPE_PRICING";

export const SAVE_ALL_DASHBOARD_REPORTS = "SAVE_ALL_DASHBOARD_REPORTS";
export const RESET_ALL_DASHBOARD_REPORTS = "RESET_ALL_DASHBOARD_REPORTS";

export const SAVE_ALL_HOTSOFT_UPDATES = "SAVE_ALL_HOTSOFT_UPDATES";
export const RESET_ALL_HOTSOFT_UPDATES = "RESET_ALL_HOTSOFT_UPDATES";

export const SAVE_HOTSOFT_UPDATE_DETAILS = "SAVE_HOTSOFT_UPDATE_DETAILS";
export const RESET_HOTSOFT_UPDATE_DETAILS = "RESET_HOTSOFT_UPDATE_DETAILS";

export const SAVE_ALL_ACTIVITIES = "SAVE_ALL_ACTIVITIES";
export const RESET_ALL_ACTIVITIES = "RESET_ALL_ACTIVITIES";

export const SAVE_ACTIVITY_DETAILS = "SAVE_ACTIVITY_DETAILS";
export const RESET_ACTIVITY_DETAILS = "RESET_ACTIVITY_DETAILS";

export const SAVE_HOTEL_NOTIFICATIONS = "SAVE_HOTEL_NOTIFICATIONS";
export const RESET_HOTEL_NOTIFICATIONS = "RESET_HOTEL_NOTIFICATIONS";